import { gsap, Power2, TimelineLite } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
import parallax from './parallax';
import LazyLoad from "vanilla-lazyload";

gsap.registerPlugin(ScrollToPlugin);

let _root;
let _allImages = 0;
let _countLoadedImages = 0;
let imagesLoaded = require('imagesLoaded');
let _tlProgress;
let _tweenInc;
let _preloaderProgress;
let _preloaderBar;

class App {
    static start() {
        return new App()
    }

    constructor() {
        _root = this;

        Promise
        .all([
            App.domReady(),
        ])
        .then(_root.init.bind(_root))

    }

    static domReady() {
        return new Promise(resolve => {
            document.addEventListener('DOMContentLoaded', resolve);
            let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });
            _allImages = _imagesLoaded.images.length;
            _imagesLoaded.on('always', _root.onAlways);
            _imagesLoaded.on('progress', _root.onProgress);

        })
    }

    onAlways(instance) {
        _root.animationStart();
    }

    animationStart() {


        const _preloader = document.getElementById('preloader');
        
        _tlProgress = new TimelineLite({ delay: 1, onComplete: _root.onInitComplete });
        
        if ('undefined' != typeof _preloader && null != _preloader) {
            _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
            _tlProgress.to(_preloader, 0.5, { autoAlpha: 0 });

            _preloaderProgress = _preloader.querySelector('.preloader-progress');
            if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
                _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
                _tlProgress.to(_preloaderProgress, 0.5, { autoAlpha: 0 });
            }
            _preloaderBar = _preloader.querySelector('.preloader-bar');
        }
        
    }

    onInitComplete() {

    }

    progressUpdate() {
        let _width = Math.round(_tweenInc.progress() * 100);
        if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
            _preloaderProgress.textContent = _width + '%';
        }
    }

    onProgress(instance, image) {
        if (image.isLoaded) {
            _countLoadedImages++;
        }

        _tweenInc = gsap.to(_preloaderBar, {
            duration: 0.5,
            width: "100%",
            onUpdate: _root.progressUpdate,
            ease: gsap.Linear
        });
    }

    static showPage() {
        
        new parallax();

        new LazyLoad({
            elements_selector: '.lazy',
            use_native: true,
            callback_loaded: _root.callBackImgLoaded
        });

        const element = document.querySelectorAll('.scroll-page-link');

        if ('undefined' != typeof element && null != element) {
            element.forEach(function(item) {
                _root.addEvent(item, 'click', _root.onCkickEvent);
            });
        }

    }

    onCkickEvent(el) {
        const _link = el.currentTarget.getAttribute('href');
        const _anchor = document.querySelector(_link);
        let scrollTop = 0;

        if ('undefined' != typeof _anchor && null != _anchor) {
            scrollTop = _anchor.getBoundingClientRect().top + window.scrollY;

        }

        gsap.to(window, {
            duration: 0,
            scrollTo: { y: scrollTop },
            ease: Power2.easeOut
        });

        el.preventDefault();
    }

    callBackImgLoaded(trg) {
        trg.parentElement.classList.contains('img-loaded') ? trg.parentElement.classList.remove('img-loaded') : trg.parentElement.classList.add('img-loaded');
    }

    init() {
        App.showPage();
    }

    onCompleteAfter() {
        _menu.refresh();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}

App.start();